import { LitElement, html, nothing } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
// import { unsafeCSS } from "lit";
// import baseStyles from "../../baseStyles.css?raw";
// import hostStyles from "./BuiChip.css?raw";
import { CSSResultGroup } from "lit";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiChip.css";

import tuneIconRaw from "@material-symbols/svg-300/rounded/tune.svg?raw";
const tuneIcon = unsafeSVG(tuneIconRaw);

@customElement("bui-chip-toggle")
export class BuiChipToggle extends LitElement {
  @property({ type: String }) selector = ".itemCatalog .productFeedContainer";
  @property({ type: String, attribute: "toggle-class" }) toggleClass = "viewFilters";
  @property({ type: Number, attribute: "show-below" }) showBelow = 1200;
  @state() private windowWidth: number = window.innerWidth;

  // static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];
  static styles = [baseStyles, hostStyles] as CSSResultGroup;

  toggleFilters = () => {
    // console.log("toggleFilters function called"); // Debug log (remove in production)

    const targetElement = document.querySelector(this.selector);
    if (targetElement) {
      // console.log("Target element found:", targetElement); // Debug log
      targetElement.classList.toggle(this.toggleClass);
      // console.log(`Class ${this.toggleClass} toggled. Current state:`, targetElement.classList.contains(this.toggleClass)); // Debug log

      // Dispatch a custom event after toggling the filter class.
      this.dispatchEvent(
        new CustomEvent("filters-toggled", {
          detail: { active: targetElement.classList.contains(this.toggleClass) },
          bubbles: true,
          composed: true,
        })
      );
    } else {
      console.log("Target element not found"); // Debug log
    }
  };

  // Handle window resize events to update windowWidth.
  private _handleResize = () => {
    this.windowWidth = window.innerWidth;
    this.requestUpdate();
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", this._handleResize);
  }

  disconnectedCallback() {
    window.removeEventListener("resize", this._handleResize);
    super.disconnectedCallback();
  }

  render() {
    // Hide the component on desktop (width >= 1200px)
    if (this.windowWidth >= this.showBelow) {
      return nothing;
    }
    return html` <button @click=${this.toggleFilters} type="button" aria-label="Toggle filters" class="bui-chip">${tuneIcon}</button> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-chip-toggle": BuiChipToggle;
  }
}
