import { LitElement, css, html } from "lit";
import { unsafeCSS } from "lit";
import { customElement } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";

@customElement("bui-icon-lists")
export class BuiIconLists extends LitElement {
  static instanceCounter = 0;

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        animation: fadeIn 0.3s ease-in-out;
      }

      .bui-icon-lists {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      /* Extra small devices (xs): 0px and up */
      @media screen and (max-width: 575.98px) {
        /* Your styles here */
      }

      /* Small devices (sm): 576px and up */
      @media screen and (min-width: 576px) {
        /* Your styles here */
      }

      /* Medium devices (md): 768px and up */
      @media screen and (min-width: 768px) {
        /* Your styles here */
      }

      /* Large devices (lg): 992px and up */
      @media screen and (min-width: 992px) {
        /* Your styles here */
      }

      /* Large devices (lg): 1024px and up */
      @media screen and (min-width: 1024px) {
        /* Your styles here */
      }

      /* Extra large devices (xl): 1200px and up */
      @media screen and (min-width: 1200px) {
        /* Your styles here */
      }
    `,
  ];

  connectedCallback(): void {
    super.connectedCallback();
    this.id = `bui-icon-lists-${BuiIconLists.instanceCounter++}`;
  }

  render() {
    return html`
      <div class="bui-icon-lists">
        <slot>
          <bui-icon-list dark></bui-icon-list>
          <bui-icon-list dark></bui-icon-list>
          <bui-icon-list dark></bui-icon-list>
          <bui-icon-list dark></bui-icon-list>
        </slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-icon-lists": BuiIconLists;
  }
}
