import { LitElement, css, html } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";
import "./bui-picture";

@customElement("bui-bgbanner-card")
export class BuiBgBannerCard extends LitElement {
  @property({ type: String }) title: string = "Our Authenticity Guarantee";
  @property({ type: String }) layout: string = "right";
  @property({ type: String }) image: string = "/pdp/images/bobs-guarantee.jpg";
  @property({ type: String }) titleId: string = `bui-bgbanner-card-title-${BuiBgBannerCard.instanceCounter++}`;
  @property({ type: Boolean }) btnMarginTop: boolean = false;

  static instanceCounter = 0;

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        animation: fadeIn 0.3s ease-in-out;
        position: relative;
        line-height: 1.75rem;
      }

      .bui-bgbanner-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
      }

      .bui-bgbanner-card .bui-bgbanner-card-picture {
        display: flex;
        z-index: -1;
      }

      .bui-bgbanner-card .bui-bgbanner-card-picture .bui-bgbanner-card-picture__img {
        min-height: 320px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .bui-bgbanner-card bui-picture {
        display: flex;
        z-index: -1;
        min-height: 320px;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .bui-bgbanner-card-wrapper--left,
      .bui-bgbanner-card-wrapper--right {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 1552px;
        margin: auto;
      }

      .bui-bgbanner-card-content {
        width: 100%;
        padding: 24px 12px;
        align-content: center;
        background-color: white;
      }

      .bui-bgbanner-card-content h2 {
        display: block;
        font-weight: 500;
        font-family: var(--bui-font-head, "utopia std"), serif;
        margin: 0 0 24px 0;
        font-size: var(--h2);
        line-height: 40px;
        font-style: normal;
      }

      .bui-bgbanner-card-content ::slotted(p) {
        color: rgba(0, 0, 0, 0.72);
      }

      .bui-bgbanner-card-wrapper--right {
        align-items: end;
      }

      .bui-bgbanner-card-wrapper--left {
        align-items: start;
      }

      /* Extra small devices (xs): 0px and up */
      @media screen and (max-width: 575.98px) {
        /* Your styles here */
      }

      /* Small devices (sm): 576px and up */
      @media screen and (min-width: 576px) {
        /* Your styles here */
      }

      /* Medium devices (md): 768px and up */
      @media screen and (min-width: 768px) {
        .bui-bgbanner-card-content {
          padding: 32px;
        }

        .bui-bgbanner-card-content h2 {
          line-height: 48px;
        }
      }

      /* Large devices (lg): 992px and up */
      @media screen and (min-width: 992px) {
        /* Your styles here */
      }

      /* Large devices (lg): 1024px and up */
      @media screen and (min-width: 1024px) {
        /* Your styles here */
      }

      /* Extra large devices (xl): 1200px and up */
      @media screen and (min-width: 1200px) {
        .bui-bgbanner-card {
          min-height: 620px;
          padding: clamp(16px, 2dvw, 64px);
        }

        /* .bui-bgbanner-card .bui-bgbanner-card-picture {
          flex-direction: row;
        }

        .bui-bgbanner-card .bui-bgbanner-card-picture .bui-bgbanner-card-picture__img {
          position: absolute;
          width: 100%;
          height: 100%;
          max-height: 620px;
          top: 0;
          left: 0;
        } */

        .bui-bgbanner-card bui-picture {
          flex-direction: row;
          position: absolute;
          width: 100%;
          height: 100%;
          max-height: 620px;
          top: 0;
          left: 0;
        }

        .bui-bgbanner-card-content {
          padding: 40px;
          max-width: 640px;
          align-content: center;
          background-color: white;
          border-radius: 24px;
        }

        .bui-bgbanner-card-content h2 {
          line-height: 56px;
        }
      }
    `,
  ];

  connectedCallback(): void {
    super.connectedCallback();
    this.id = `bui-bgbanner-card-${BuiBgBannerCard.instanceCounter++}`;
  }

  render() {
    const pageWidth = document.documentElement.clientWidth;

    return html`
      <section class="bui-bgbanner-card" role="banner" aria-labelledby="${this.titleId}">
        <bui-picture img-src="${this.image}" img-loading="lazy" img-priority="low" img-alt="${this.title} image" img-ratio="16/9" img-fit="cover" img-width="${pageWidth}" class="bui-bgbanner-card-picture"></bui-picture>
        <div class="bui-bgbanner-card-wrapper--${this.layout}">
          <div class="bui-bgbanner-card-content">
            <h2>${this.title}</h2>
            <div class="bui-bgbanner-card-content__inner">
              <slot name="content"></slot>
            </div>
            <div class="bui-bgbanner-card-content__button ${this.btnMarginTop ? "btn-margin-top" : ""}">
              <slot name="cta"></slot>
            </div>
          </div>
        </div>
      </section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-bgbanner-card": BuiBgBannerCard;
  }
}
