import { LitElement, html } from "lit";
import { CSSResultGroup } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiChip.css";

@customElement("bui-chip")
export class BuiChip extends LitElement {
  @property({ type: String }) text = "Brand";
  @property({ type: String, attribute: "img-src" }) imgSrc = "/images/Used-Rolex-Submariner-126610-Black-Chromalight-Dial-SKU167399.jpg";
  @property({ type: String, attribute: "img-loading" }) imgLoading: "eager" | "lazy" = "eager";
  @property({ type: String }) variant = "simple";
  @property({ type: String, attribute: "bg-color" }) bgColor = "";
  @property({ type: String, attribute: "bg-size" }) bgSize = "";
  @property({ type: String, attribute: "bg-pos" }) bgPos = "";
  @property({ type: String, attribute: "bg-img" }) bgImg = "";
  @property({ type: String, attribute: "bg-full" }) bgFull = "";
  @property({ type: String }) link = "#";
  @state() private windowWidth: number = window.innerWidth;

  // Declare a resize timeout property
  private _resizeTimeout?: number;

  // static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];
  static styles = [baseStyles, hostStyles] as CSSResultGroup;

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", this._handleResize);
  }

  disconnectedCallback() {
    window.removeEventListener("resize", this._handleResize);
    super.disconnectedCallback();
  }

  // Debounced resize handler to improve performance on window resize events
  _handleResize = () => {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = window.setTimeout(() => {
      this.windowWidth = window.innerWidth;
    }, 100);
  };

  firstUpdated() {
    this.classList.remove("skeleton");
    this.removeAttribute("style");
  }

  // Helper method to compute the style object
  _computeStyles() {
    const { bgColor, bgImg, bgSize, bgPos, bgFull } = this;
    return {
      ...(bgImg
        ? {
            backgroundImage: `url(${bgImg})`,
            backgroundSize: bgSize || "cover",
            backgroundPosition: bgPos || "center",
          }
        : {
            ...(bgSize && { backgroundSize: bgSize }),
            ...(bgPos && { backgroundPosition: bgPos }),
          }),
      backgroundRepeat: bgImg || bgSize || bgPos ? "no-repeat" : undefined,
      ...(bgColor && { backgroundColor: bgColor }),
      ...(bgFull && { background: bgFull }),
    };
  }

  render() {
    const { imgSrc, text, variant, link, imgLoading } = this;
    const mobile = this.windowWidth <= 1199;

    // Simplified variant mapping
    const variantClasses = {
      brand: "bui-chip--brand",
      color: "bui-chip--color",
      simple: "bui-chip--simple",
    };
    // @ts-ignore
    const variantClass = variantClasses[variant] || "";

    // Use 160px for non-mobile brand variant, otherwise 24px
    const imgHeight = !mobile && variant === "brand" ? 160 : 24;
    const styles = this._computeStyles();

    return html`
      <a href="${link}" title="${text} Watches" class="bui-chip ${variantClass}">
        <div class="bui-chip-container" style=${styleMap(styles)}>
          <bui-picture img-src="${imgSrc}" img-loading="${imgLoading}" img-priority="auto" img-fit="contain" img-height="${imgHeight}" img-alt="Image of ${text} watch"></bui-picture>
        </div>
        <span aria-label="${text}">${text}</span>
      </a>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-chip": BuiChip;
  }
}
