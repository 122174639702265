import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { SignalWatcher, html, signal } from "@lit-labs/signals";
import classes from "./BuiInput.module.css";

@customElement("bui-input")
export class BuiInput extends SignalWatcher(LitElement) {
  @property({ type: String, attribute: "input-label" }) label = "Label";
  @property({ type: String, attribute: "input-placeholder" }) placeholder = "";
  @property({ type: String, attribute: "input-type" }) type = "text";
  @property({ type: String, attribute: "input-id" }) inputID = `${Math.random().toString(36).slice(2, 10)}`;
  @property({ type: String, attribute: "input-autocomplete" }) autocomplete = "off";
  @property({ type: String, attribute: "leading-icon" }) leadingIcon?: String;
  @property({ type: String, attribute: "trailing-icon" }) trailingIcon?: String;
  @property({ type: Boolean, attribute: "auto-icons" }) autoIcons = false;
  @property({ type: Boolean }) required = false;
  @property({ type: Boolean }) showCount = false;
  @property({ type: Boolean }) showHelper = false;
  @property({ type: Boolean }) disabled = false;
  @property({ type: String, attribute: "currency-symbol" }) currencySymbol = ""; // New property for currency symbol
  @property({ type: Object }) count = signal<Number>(0);

  // New property to track password visibility
  private isPasswordVisible = signal(false);

  createRenderRoot() {
    return this; // Render in the light DOM
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.type === "password" && !this.trailingIcon) {
      this.setAttribute("trailing-icon", "visibility");
    }
    this.placeholder = this.placeholder || this.label;
  }

  // Method to handle input changes
  private handleInputChange(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    if (this.type === "currency") {
      // Remove non-numeric characters (e.g., commas, currency symbol)
      const numericValue = value.replace(/[^0-9]/g, "");
      // Format the numeric value as currency
      value = this.formatCurrency(parseFloat(numericValue) || 0);
      inputElement.value = value; // Update the input value
    }

    this.count.set(value.length);
  }

  // Method to format a numeric value as currency
  private formatCurrency(value: number): string {
    return `${this.currencySymbol}${value.toLocaleString()}`;
  }

  // Method to toggle password visibility
  private togglePasswordVisibility() {
    this.isPasswordVisible.set(!this.isPasswordVisible.get());
  }

  private autoIconLogic(icon: string) {
    return this.autoIcons ? (this.leadingIcon = icon) : null;
  }

  private computeType(type: string) {
    switch (type) {
      case "tel":
        return {
          inputmode: "tel", // Show telephone keypad on mobile
          pattern: "[0-9]*", // Allow only numbers (optional)
          leadingIcon: this.autoIconLogic("call"), // Default icon for tel
          placeholder: this.placeholder,
        };
      case "number":
        return {
          inputmode: "numeric", // Default input mode
          leadingIcon: this.autoIconLogic("pin"), // Default icon for email
          placeholder: this.placeholder,
        };
      case "password":
        return {
          inputmode: "text", // Default input mode
          leadingIcon: this.autoIconLogic("lock"),
          placeholder: this.placeholder,
        };
      case "email":
        return {
          inputmode: "email", // Show email keypad on mobile
          leadingIcon: this.autoIconLogic("email"), // Default icon for email
          placeholder: this.placeholder,
        };
      case "currency": // New case for currency
        return {
          inputmode: "numeric", // Show numeric keypad on mobile
          leadingIcon: this.autoIconLogic("attach_money"), // Default icon for currency
          placeholder: this.placeholder,
        };
      default:
        return {
          inputmode: "text", // Default input mode
          leadingIcon: this.leadingIcon, // Use provided trailing icon
          trailingIcon: this.trailingIcon, // Use provided trailing icon
          placeholder: this.placeholder,
        };
    }
  }

  protected render() {
    const inputLeadingIcon = this.leadingIcon || this.computeType(this.type).leadingIcon;
    const inputTrailingIcon = this.trailingIcon || this.computeType(this.type).trailingIcon;

    const inputClasses = [
      classes["input-container"], // Always include the base class
      inputLeadingIcon && inputTrailingIcon && classes["with-both-icons"], // Conditionally add "with-both-icons"
      inputLeadingIcon && !inputTrailingIcon && classes["with-leading-icon"], // Conditionally add "with-leading-icon"
      inputTrailingIcon && !inputLeadingIcon && classes["with-trailing-icon"], // Conditionally add "with-trailing-icon"
    ]
      .filter(Boolean) // Remove falsy values (e.g., empty strings)
      .join(" "); // Join the classes with a space

    // Determine the trailing icon based on password visibility
    const trailingIcon = this.type === "password" ? (this.isPasswordVisible.get() ? "visibility_off" : "visibility") : inputTrailingIcon;
    const typeLogic = this.type === "password" && this.isPasswordVisible.get() ? "text" : this.type === "currency" ? "numeric" : this.type;

    return html`
      <div class="${inputClasses}">
        ${inputLeadingIcon ? html`<span class="material-symbols-rounded ${classes["leading-icon"]}">${inputLeadingIcon}</span>` : null}
        <input .autocomplete="${this.autocomplete}" name="${this.inputID}" id="${this.inputID}" .type="${typeLogic}" .placeholder="${this.placeholder}" inputmode="${this.computeType(this.type).inputmode}" .pattern?="${this.computeType(this.type).pattern}" ?required="${this.required}" ?disabled="${this.disabled}" tabindex="0" @input="${this.handleInputChange}" />
        <label class="${classes["input-label"]}" for="${this.inputID}">${this.label}</label>
        ${trailingIcon ? html` <span class="material-symbols-rounded ${classes["trailing-icon"]}" @click="${this.togglePasswordVisibility}"> ${trailingIcon} </span> ` : null}
      </div>
      ${this.showHelper || this.showCount ? html` <div class="${classes["error-count-container"]}">${this.showCount ? html`<span class="${classes["char-count"]}">Characters: ${this.count.get()}</span>` : ""}</div> ` : ""}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-input": BuiInput;
  }
}
