import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";
// import { globalStyles } from "../styles";
// import classes from "./BuiPictureLd.module.css";

@customElement("bui-picture")
export class BuiPicture extends LitElement {
  @property({ type: String }) brand = "Rolex";
  @property({ type: String }) model = "Submariner";
  @property({ type: Boolean, attribute: "img-preload" }) imgPreload = false;
  @property({ type: String }) imgCDN = "https://images.bobswatches.com";
  @property({ type: String, attribute: "subdomain" }) imgSubdomain = "";
  @property({ type: Number, attribute: "img-effort" }) effort = 3;
  @property({ type: Number, attribute: "img-quality" }) quality = 40;
  @property({ type: String, attribute: "img-fit" }) imgFit = "contain";
  @property({ type: String, attribute: "img-sharpness" }) sh = "true";
  @property({ type: String, attribute: "img-width" }) imgWidth = "";
  @property({ type: String, attribute: "img-height" }) imgHeight = "";
  @property({ type: String, attribute: "img-loading" }) imgLoading: "eager" | "lazy" = "lazy";
  @property({ type: String, attribute: "img-priority" }) imgPriority = "low";
  @property({ type: String, attribute: "img-src" }) imgSrc = "/images/Used-Rolex-Submariner-126610-Black-Chromalight-Dial-SKU167399.jpg";
  @property({ type: String, attribute: "img-alt" }) imgAlt = `${this.brand} ${this.model}`;
  @property({ type: String, attribute: "img-ratio" }) imgRatio = "auto";
  @property({ type: Boolean }) fluid = false;

  // createRenderRoot() {
  //   return this;
  // }

  private resizeObserver: ResizeObserver | null = null;

  static styles = [
    unsafeCSS(baseStyles),
    // globalStyles,
    css`
      :host {
        display: block;
        width: fit-content;
      }

      :host([fluid]) {
        height: 100%;
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: var(--img-fit, contain);
        aspect-ratio: var(--img-ratio, auto);
      }
    `,
  ];

  connectedCallback(): void {
    super.connectedCallback();

    // Run browser detection on load
    // this.detectRealBrowser();

    // // Check if the visitor has a stored browser session
    // const isHuman = localStorage.getItem("human_visitor") === "true";

    // if (!isHuman) {
    //   // Assume it's a bot and load the image from the origin
    //   this.imgCDN = ""; // Or your original image URL
    // }

    if (this.fluid) {
      this.observeSize();
    }

    // Check if preloading is enabled
    if (!this.imgPreload) return;

    // Construct the base URL
    const baseSrc = this.imgCDN + this.imgSrc;
    const params = `?ef=${this.effort}&q=${this.quality}&fit=${this.imgFit}&sh=${this.sh}${this.imgWidth ? `&w=${this.imgWidth}` : ""}${this.imgHeight ? `&h=${this.imgHeight}` : ""}${this.imgSubdomain ? `&sbd=${this.imgSubdomain}` : ""}`;

    // Round devicePixelRatio to the nearest tenth
    const dpr = Math.round((window.devicePixelRatio || 1) * 10) / 10;

    // URLs for AVIF, WebP, and JPEG
    const formats = ["avif"];

    formats.forEach((format) => {
      const preloadSrc = `${baseSrc}${params}&fmt=${format}&dpr=${dpr}`;

      // Check if the preload link already exists
      if (!document.querySelector(`link[rel="preload"][href="${preloadSrc}"]`)) {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = preloadSrc;
        document.head.appendChild(link);
      }
    });
  }

  // Add the detection function inside the class
  // private detectRealBrowser() {
  //   const isBot = /bot|crawl|spider/i.test(navigator.userAgent) || navigator.webdriver;

  //   if (!isBot) {
  //     localStorage.setItem("human_visitor", "true");
  //   }
  // }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  private observeSize() {
    let resizeTimeout: number;
    this.resizeObserver = new ResizeObserver((entries) => {
      // Clear any existing timeout
      window.clearTimeout(resizeTimeout);
      // Set a new timeout that executes after, say, 200ms of no resize events.
      resizeTimeout = window.setTimeout(() => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect;
          this.imgWidth = Math.round(width).toString();
          this.imgHeight = Math.round(height).toString();
        }
      }, 200);
    });
    this.resizeObserver.observe(this);
  }

  protected render() {
    const baseSrc = this.imgCDN + this.imgSrc;
    const params = `?ef=${this.effort}&q=${this.quality}&fit=${this.imgFit}&sh=${this.sh}${this.imgWidth ? `&w=${this.imgWidth}` : ""}${this.imgHeight ? `&h=${this.imgHeight}` : ""}${this.imgSubdomain ? `&sbd=${this.imgSubdomain}` : ""}`;
    // const isFluid = this.fluid ? "100%" : "max-content"; add this to the picture element: width: ${isFluid}"
    const imgStyles = `--img-fit: ${this.imgFit}; --img-ratio: ${this.imgRatio};`;

    const fallbackSrc = `https://${this.imgSubdomain + "bobswatches.com" + this.imgSrc}`;

    return html`
      <picture style="display: block; height: ${this.imgHeight + "px"}; width: 100%">
        <source srcset="${baseSrc}${params}&fmt=avif&dpr=1 1x, ${baseSrc}${params}&fmt=avif&dpr=1.5 1.5x, ${baseSrc}${params}&fmt=avif&dpr=1.8 1.8x, ${baseSrc}${params}&fmt=avif&dpr=2 2x, ${baseSrc}${params}&fmt=avif&dpr=3 3x" type="image/avif" />
        <source srcset="${baseSrc}${params}&fmt=webp&dpr=1 1x, ${baseSrc}${params}&fmt=webp&dpr=1.5 1.5x, ${baseSrc}${params}&fmt=webp&dpr=1.8 1.8x, ${baseSrc}${params}&fmt=webp&dpr=2 2x, ${baseSrc}${params}&fmt=webp&dpr=3 3x" type="image/webp" />
        <source srcset="${baseSrc}${params}&fmt=jpeg&dpr=1 1x, ${baseSrc}${params}&fmt=jpeg&dpr=1.5 1.5x, ${baseSrc}${params}&fmt=jpeg&dpr=1.8 1.8x, ${baseSrc}${params}&fmt=jpeg&dpr=2 2x, ${baseSrc}${params}&fmt=jpeg&dpr=3 3x" type="image/jpeg" />
        <img part="img" width="${this.imgWidth}" height="${this.imgHeight}" class="bui-watch-card-picture__img" loading="${this.imgLoading}" decoding="${this.imgLoading === "lazy" ? "async" : "sync"}" fetchpriority="${this.imgLoading === "lazy" ? "low" : "high"}" src="${baseSrc}" alt="${this.imgAlt}" title="${this.imgAlt}" style="${imgStyles}" onerror="this.onerror=null; this.src='${fallbackSrc}';" />
      </picture>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-picture": BuiPicture;
  }
}
