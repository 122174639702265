import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";
// import { globalStyles } from "../styles";

@customElement("bui-pdp-accordion-item")
export class BuiPdpAccordionItem extends LitElement {
  // Define styles
  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        animation: fadeIn 0.3s ease-in-out;
        container: bui-pdp-accordion-item / inline-size;
        display: block;
        width: 100%;
        font-family: var(--bui-font-sans, sans-serif);
        border-bottom: 1px solid #d9d9d9;
        color: var(--mgray800);
        line-height: 1.75;
      }

      button {
        display: flex;
        width: 100%;
        text-align: left;
        padding: 16px;
        background: none;
        border: none;
        outline: none;
        align-items: center;
        cursor: pointer;
        gap: 16px;
        user-select: text;
      }

      button h2 {
        flex: 1;
        font-size: 24px;
        color: var(--mgray950);
        line-height: 1;
        font-weight: 400;
        font-family: var(--bui-font-serif, serif);
        margin: 0;
      }

      button:hover {
        /* background-color: #f6f6f6; */
      }

      button::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 8px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljk5OTk5IDMuODY4NjNMOC40MzQzIDAuNDM0MzExTDkuNTY1NjcgMS41NjU2OEw0Ljk5OTk5IDYuMTMxMzdMMC40MzQzMDMgMS41NjU2OEwxLjU2NTY3IDAuNDM0MzExTDQuOTk5OTkgMy44Njg2M1oiIGZpbGw9ImJsYWNrIi8+PC9zdmc+");
        background-size: cover;
        margin-left: auto;
        transition: transform 0.3s ease;
      }

      button:focus {
        outline: none;
        /* background-color: #fafafa; */
      }

      button[aria-expanded="true"]::after {
        transform: rotate(-180deg);
      }

      .collapse {
        overflow: hidden;
        transition: height 0.3s ease, padding 0.3s ease;
      }

      .xy-padding {
        padding: 16px;
      }

      .y-padding {
        padding: 24px 0;
      }

      .bui-pdp-accordion-item__stars {
        display: flex;
        flex-direction: row;
        gap: 12px;
        place-items: center;
      }

      @container (width <= 575.98px) {
        .bui-pdp-accordion-item__stars {
          gap: 4px;
        }

        button h2 {
          line-height: 28px;
        }
      }

      @media screen and (max-width: 575.98px) {
        /* Additional small device styles */
      }

      @media screen and (min-width: 576px) {
        /* Small device styles */
      }

      @media screen and (min-width: 768px) {
        /* Medium device styles */
      }

      @media screen and (min-width: 992px) {
        /* Large device styles */
      }

      @media screen and (min-width: 1024px) {
        button h2 {
          font-size: 36px;
        }

        .y-padding {
          padding: 32px 0;
        }
      }

      @media screen and (min-width: 1200px) {
        /* Extra large device styles */
      }
    `,
  ];

  // Define properties using decorators
  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Number }) index = 0;
  @property({ type: String }) header = `accordion item #${BuiPdpAccordionItem.titleCounter++}`;
  @property({ type: Boolean, reflect: true }) noPadding = false;
  @property({ type: Boolean }) review = false;

  // Static counters for unique IDs and titles
  static instanceCounter = 0;
  static titleCounter = 1;
  static sectCounter = 0;

  // Internal state for the section ID
  @state() private sect = `bui-sect-${BuiPdpAccordionItem.sectCounter++}`;

  constructor() {
    super();
    this.id = `bui-pdp-accordion-item-${BuiPdpAccordionItem.instanceCounter++}`;
  }

  updated(changedProperties: Map<string, unknown>) {
    if (changedProperties.has("open")) {
      this.updateHeight();
    }
  }

  updateHeight() {
    setTimeout(() => {
      const content: any = this.shadowRoot?.querySelector(".collapse");
      if (content) {
        if (this.open) {
          content.style.height = `${content.scrollHeight}px`;
        } else {
          content.style.height = "0";
        }
      }
    }, 100);
  }

  toggle() {
    this.open = !this.open;
    this.dispatchEvent(
      new CustomEvent("toggle-item", {
        detail: { index: this.index },
        bubbles: true,
        composed: true,
      })
    );
  }

  render() {
    const padding = this.noPadding ? "y-padding" : "xy-padding";
    return html`
      <div class="${this.open ? "bui-pdp-accordion-item--open" : "bui-pdp-accordion-item"}" id="${this.id}">
        <button type="button" @click=${this.toggle} class="bui-pdp-accordion-item__button ${padding} ${this.review ? "btn-review" : ""}" aria-expanded="${this.open}" aria-controls="${this.sect}">
          <h2>${this.header}</h2>
          ${this.review
            ? html`<div class="bui-pdp-accordion-item__stars">
                ${Array(5)
                  .fill(null)
                  .map(
                    () => html`<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.52447 1.46353C9.67415 1.00287 10.3259 1.00287 10.4755 1.46353L12.1329 6.56434C12.1998 6.77035 12.3918 6.90983 12.6084 6.90983H17.9717C18.4561 6.90983 18.6575 7.52964 18.2656 7.81434L13.9266 10.9668C13.7514 11.0941 13.678 11.3198 13.745 11.5258L15.4023 16.6266C15.552 17.0873 15.0248 17.4704 14.6329 17.1857L10.2939 14.0332C10.1186 13.9059 9.88135 13.9059 9.70611 14.0332L5.3671 17.1857C4.97524 17.4704 4.448 17.0873 4.59768 16.6266L6.25503 11.5258C6.32197 11.3198 6.24864 11.0941 6.07339 10.9668L1.73438 7.81434C1.34253 7.52964 1.54392 6.90983 2.02828 6.90983H7.39159C7.6082 6.90983 7.80018 6.77035 7.86712 6.56434L9.52447 1.46353Z"
                        fill="black"
                      />
                    </svg>`
                  )}
              </div>`
            : null}
        </button>
        <div class="collapse" id="${this.sect}">
          <div id=${this.sect} class="${padding}" role="region" aria-labelledby="${this.id}" style="${padding ? "padding-top: 0;" : ""}">
            <slot> <strong>This is the ${this.header}'s body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. </slot>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-pdp-accordion-item": BuiPdpAccordionItem;
  }
}
