import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
// import { unsafeCSS } from "lit";
// import baseStyles from "../../baseStyles.css?raw";
// import hostStyles from "./BuiEmbla.css?raw";
import { CSSResultGroup } from "lit";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiEmbla.css";
import EmblaCarousel, { EmblaCarouselType } from "embla-carousel";

const categoryStyles = css`
  .productFeedContainer {
    margin-top: 0;
  }

  @media only screen and (max-width: 800px) {
    .productFeedContainer .topRightToolbar select {
      max-width: unset;
    }
  }

  @media screen and (max-width: 1199px) {
    .bui-embla-wrapper {
      --topPost: 156px;
      position: sticky;
      top: var(--topPost);
      z-index: 1;
      background-color: white;
      /* padding: 12px 0; */
      border-bottom: 1px solid var(--mgray300);
    }

    .productFeedContainer::before,
    .mobileShowFilters {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    .chip-toggle-wrapper {
      display: none;
    }
  }
`;

import chevronLeftIconRaw from "@material-symbols/svg-300/rounded/chevron_left.svg?raw";
import chevronRightIconRaw from "@material-symbols/svg-300/rounded/chevron_right.svg?raw";

const chevronLeft = unsafeSVG(chevronLeftIconRaw);
const chevronRight = unsafeSVG(chevronRightIconRaw);

@customElement("bui-embla")
export class BuiEmbla extends LitElement {
  @property({ type: Number }) slidesToScroll = 4;

  private emblaApi?: EmblaCarouselType;
  private _canScrollPrev = false;
  private _canScrollNext = false;
  private selectHandler: (() => void) | null = null;

  @state() canScrollPrev = false;
  @state() canScrollNext = false;

  // static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];
  static styles = [baseStyles, hostStyles] as CSSResultGroup;

  constructor() {
    super();
    this.selectHandler = this.onEmblaSelect.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.hasAttribute("sticky")) {
      // Check if the style element already exists in the head
      if (!document.head.querySelector("style[data-bui-embla-sticky]")) {
        // Create a <style> element
        const styleElement = document.createElement("style");

        // Add a data attribute so we can detect it later
        styleElement.setAttribute("data-bui-embla-sticky", "true");

        // Assign the CSS string to its textContent
        styleElement.textContent = categoryStyles.cssText;

        // Append it to the <head> so it takes effect
        document.head.appendChild(styleElement);
      }
    }
  }

  firstUpdated() {
    this.initializeEmbla();
  }

  disconnectedCallback() {
    this.destroyEmbla();
    super.disconnectedCallback();
  }

  private initializeEmbla() {
    const emblaNode = this.shadowRoot?.querySelector(".embla");
    const container = this.shadowRoot?.querySelector(".embla__container");
    const slot = this.shadowRoot?.querySelector("slot");

    if (!emblaNode || !container || !slot) {
      console.error("Missing required elements for Embla initialization.");
      return;
    }

    // Get slides from the slot
    const slides = slot.assignedElements({ flatten: true });
    container.innerHTML = "";
    slides.forEach((slide) => container.appendChild(slide));

    // Embla's options
    const options = {
      loop: false,
      slidesToScroll: this.slidesToScroll,
      breakpoints: {
        "(max-width:1199px)": { watchDrag: false },
        "(min-width:1200px)": { watchDrag: true },
      },
    };

    this.emblaApi = EmblaCarousel(emblaNode as HTMLElement, options);

    // Initialize scroll state
    this._canScrollPrev = this.emblaApi.canScrollPrev();
    this._canScrollNext = this.emblaApi.canScrollNext();
    Promise.resolve().then(() => {
      this.canScrollPrev = this._canScrollPrev;
      this.canScrollNext = this._canScrollNext;
    });

    // Add "select" listener
    if (this.selectHandler && this.emblaApi) {
      this.emblaApi.on("select", this.selectHandler);
    }
  }

  private destroyEmbla() {
    if (this.emblaApi) {
      if (this.selectHandler) {
        this.emblaApi.off("select", this.selectHandler);
      }
      this.emblaApi.destroy();
      this.emblaApi = undefined;
    }
  }

  private onEmblaSelect() {
    if (!this.emblaApi) return;
    this._canScrollPrev = this.emblaApi.canScrollPrev();
    this._canScrollNext = this.emblaApi.canScrollNext();

    Promise.resolve().then(() => {
      this.canScrollPrev = this._canScrollPrev;
      this.canScrollNext = this._canScrollNext;
    });
  }

  scrollPrev() {
    if (!this.emblaApi) return;
    this.emblaApi.scrollPrev();
    this.onEmblaSelect();
  }

  scrollNext() {
    if (!this.emblaApi) return;
    this.emblaApi.scrollNext();
    this.onEmblaSelect();
  }

  render() {
    // If either scrolling direction is available, show the controls.
    const showControls = this.canScrollPrev || this.canScrollNext;
    return html`
      <div class="embla">
        <div class="embla__container">
          <slot></slot>
        </div>
      </div>
      ${showControls
        ? html`
            <div class="embla__controls">
              <button class="embla__prev" @click=${this.scrollPrev} ?disabled=${!this.canScrollPrev}>${chevronLeft}</button>
              <button class="embla__next" @click=${this.scrollNext} ?disabled=${!this.canScrollNext}>${chevronRight}</button>
            </div>
          `
        : ""}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-embla": BuiEmbla;
  }
}
