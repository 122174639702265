import { LitElement, nothing } from "lit";
import { customElement, property } from "lit/decorators.js";
import { SignalWatcher, html } from "@lit-labs/signals";
// import { unsafeCSS } from "lit";
// import baseStyles from "../../baseStyles.css?raw";
// import hostStyles from "./BuiHpBanner.css?raw";
import { CSSResultGroup } from "lit";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiHpBanner.css";
import "../bui-button";
// import "./bui-shaka";

@customElement("bui-hp-banner")
export class BwHpBanner extends SignalWatcher(LitElement) {
  @property({ type: String, attribute: "cust-id" }) custID = "rwgu7tlilc1p6v9i";
  @property({ type: String, attribute: "desktop-vid-id" }) desktopVidId = "fff417a04726099f685d1ebbcd3d45fe";
  @property({ type: String, attribute: "desktop-poster" }) desktopPoster = "";
  @property({ type: String, attribute: "desktop-src" }) desktopSrc = "";
  @property({ type: String, attribute: "mobile-vid-id" }) mobileVidId = "23d95e0117678e0ea73b739c3fb372ef";
  @property({ type: String, attribute: "mobile-poster" }) mobilePoster = "";
  @property({ type: String, attribute: "mobile-src" }) mobileSrc = "";
  @property({ type: String }) line1? = "";
  @property({ type: String }) line2 = "Luxury Watches";
  @property({ type: Boolean, attribute: "one-button" }) oneButton = false;
  @property({ type: String, attribute: "button-style" }) btnVariant = "transparent-blk";
  @property({ type: String, attribute: "button-size" }) btnSize = "xl";
  @property({ type: String, attribute: "button1-label" }) btn1Label = "Buy a Watch";
  @property({ type: String, attribute: "button1-link" }) btn1Link = "/rolex/";
  @property({ type: String, attribute: "button2-label" }) btn2Label = "Sell a Watch";
  @property({ type: String, attribute: "button2-link" }) btn2Link = "/sell-rolex-watch/";

  connectedCallback(): void {
    super.connectedCallback();
  }

  // static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];
  static styles = [baseStyles, hostStyles] as CSSResultGroup;

  // move this inside the render if for a shaka player version
  // <bui-shaka cust-id="${this.custID}" desktop-vid-id="${this.desktopVidId}" mobile-vid-id="${this.mobileVidId}"></bui-shaka>

  render() {
    const { line1, line2, oneButton, btnVariant, btnSize, btn1Label, btn1Link, btn2Label, btn2Link, desktopSrc, mobileSrc } = this;
    const isMobile = screen.width < 640;
    const mobileVidSrc = `https://videos.bobswatches.com${mobileSrc}?w=780`;
    const desktopVidSrc = `https://videos.bobswatches.com${desktopSrc}?w=2560`;
    const mobileVidPoster = `https://videos.bobswatches.com${mobileSrc}?w=780&poster=true`;
    const desktopVidPoster = `https://videos.bobswatches.com${desktopSrc}?w=2560&poster=true`;

    return html`
      <div class="bui-hp-banner-container">
        <div class="bui-hp-title-buttons">
          <h2 class="bui-hp-title-buttons__title">
            <span class="bui-hp-title-buttons__line1">${line1 ? line1 : nothing}</span>
            <span class="bui-hp-title-buttons__line2">${line2}</span>
          </h2>
          <div class="bui-hp-banner-buttons">
            ${oneButton ? nothing : html`<bui-button kind="link" link="${btn1Link}" label="${btn1Label}" size="${btnSize}" variant="${btnVariant}"></bui-button>`}
            <bui-button kind="link" link="${btn2Link}" label="${btn2Label}" size="${btnSize}" variant="${btnVariant}"></bui-button>
          </div>
        </div>
        ${isMobile ? html`<video src="${mobileVidSrc}" part="mobile-video" id="mobile-video" poster="${mobileVidPoster}" autoplay muted loop playsinline></video>` : html`<video src="${desktopVidSrc}" style="display: block; width: 100%; height: 100%; object-fit: cover;" part="desktop-video" id="desktop-video" poster="${desktopVidPoster}" autoplay muted loop playsinline></video>`}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-hp-banner": BwHpBanner;
  }
}
