import { LitElement, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { SignalWatcher, html } from "@lit-labs/signals";
import baseStyles from "../baseStyles.css?raw";
import "./bui-accordion-item"; // Ensure this import is correct

@customElement("bui-accordion")
export class BuiAccordion extends SignalWatcher(LitElement) {
  @property({ type: Boolean, reflect: true }) noBorder = false;
  @property({ type: Boolean }) autoclose = false;
  @property({ type: Boolean }) noPadding = false;
  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Number }) index = 0;
  @property({ type: Number }) openIndex = -1;
  @property({ type: String }) id = "";

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        display: block;
      }

      .accordion {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      .no-border {
        border: none;
        border-radius: 0;
      }

      .border {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
      }

      ::slotted(bui-accordion-item) {
        border-bottom: 1px solid #e0e0e0;
      }

      ::slotted(bui-accordion-item:last-of-type) {
        border-bottom: none;
      }

      ::slotted(bui-accordion-item[open]) {
        background-color: #fff;
      }
    `,
  ];

  static instanceCounter = 0;

  connectedCallback() {
    super.connectedCallback();
    this.id = `bui-accordion-${BuiAccordion.instanceCounter++}`;
  }

  handleToggle(index: number) {
    this.openIndex = this.openIndex === index ? -1 : index;
    this.requestUpdate();
  }

  handleSlotChange(e: Event) {
    const slot = e.target as HTMLSlotElement;
    const assignedNodes = slot.assignedNodes({ flatten: true });

    assignedNodes.forEach((node, index) => {
      // Narrow down the type to Element before accessing `tagName`
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        if (element.tagName.toLowerCase() === "bui-accordion-item") {
          const item = element as HTMLElement & { index: number; open: boolean };
          item.index = index;
          item.addEventListener("toggle-item", (event: Event) => {
            const customEvent = event as CustomEvent<{ index: number }>;
            this.handleToggle(customEvent.detail.index);
          });

          if (this.autoclose) {
            item.open = index === this.openIndex;
          }
        }
      }
    });
  }

  updated() {
    if (this.autoclose) {
      const slot = this.shadowRoot?.querySelector("slot");
      const assignedNodes = slot?.assignedNodes({ flatten: true }) || [];

      assignedNodes.forEach((node, index) => {
        // Narrow down the type to Element before accessing `tagName`
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as Element;
          if (element.tagName.toLowerCase() === "bui-accordion-item") {
            const item = element as HTMLElement & { open: boolean };
            item.open = index === this.openIndex;
          }
        }
      });
    }
  }

  render() {
    const noBorderClass = this.noBorder ? "no-border" : "border";

    return html`
      <div class="accordion ${noBorderClass}" id="${this.id}">
        <slot @slotchange=${this.handleSlotChange}></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-accordion": BuiAccordion;
  }
}
