import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";

@customElement("bui-pdp-accordion")
export class BuiPdpAccordion extends LitElement {
  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        animation: fadeIn 0.3s ease-in-out;
      }

      ::slotted(bui-pdp-accordion-item:last-of-type) {
        border-bottom: 1px solid transparent;
      }
    `,
  ];

  static instanceCounter = 0;

  connectedCallback() {
    super.connectedCallback();
    this.id = `bui-pdp-accordion-${BuiPdpAccordion.instanceCounter++}`;
  }

  render() {
    return html` <slot></slot> `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-pdp-accordion": BuiPdpAccordion;
  }
}
