import { LitElement, html, nothing, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";
// import { globalStyles } from "../styles";

@customElement("bui-overline-text")
export class BuiOverlineText extends LitElement {
  @property({ type: String }) title = "Title Text";
  @property({ type: String }) overline = "";
  @property({ type: Boolean }) reverse = false;

  static styles = [
    unsafeCSS(baseStyles),
    // globalStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
      }

      .bui-overline-container {
        font-weight: 500;
        margin: 0;
      }

      .bui-overline-container__overline {
        font-family: var(--bui-font-sans, Inter), sans-serif;
        display: block;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2px;
        color: var(--overline-color);
      }

      .bui-overline-container__title {
        display: block;
        font-family: var(--bui-font-serif, "utopia std"), serif;
        margin: 0;
        /* font-size: 48px; */
        font-size: var(--h2);
        line-height: 56px;
        font-style: normal;
      }

      @media screen and (max-width: 1199px) {
        .bui-overline-container__title {
          /* font-size: 40px; */
          line-height: 48px;
        }
      }

      @media screen and (max-width: 767px) {
        .bui-overline-container__title {
          /* font-size: 32px; */
          line-height: 40px;
        }
      }
    `,
  ];

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const isReverse = this.reverse === true;
    return html`
      <h2 class="bui-overline-container">
        ${this.overline ? html`<span class="${isReverse ? "bui-overline-container__title" : "bui-overline-container__overline"}" style="margin-bottom: 4px;">${isReverse ? this.title : this.overline}</span>` : nothing}
        <span class="${isReverse ? "bui-overline-container__overline" : "bui-overline-container__title"}">${isReverse ? this.overline : this.title}</span>
      </h2>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-overline-text": BuiOverlineText;
  }
}
