import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { signal } from "@lit-labs/preact-signals";
import baseStyles from "../baseStyles.css?raw";
// import { globalStyles } from "../styles";
import "./bui-overline-text";
import "./bui-picture";
import "./bui-ytembed.ts";

@customElement("bui-fw-banner")
export class BuiFwBanner extends LitElement {
  @property({ type: String }) variant: string = "";
  @property({ type: String, attribute: "img-src" }) imgSrc: string = "/images/model-images/about-omega.jpg";
  @property({ type: String, attribute: "img-ratio" }) imgRatio: string = "16/8";
  @property({ type: String, attribute: "img-fit" }) imgFit: string = "cover";
  @property({ type: String }) layout: string = "";
  @property({ type: String, attribute: "lazy-loading" }) lazyLoading: "lazy" | "eager" = "eager";
  @property({ type: String, attribute: "img-priority" }) imgPriority: string = "";
  @property({ type: String, attribute: "img-alt" }) altTag: string = "";
  @property({ type: String }) overline: string = "Omega";
  @property({ type: String }) title: string = "Speedmaster";
  @property({ type: String }) videoID: string = "";
  @property({ type: Boolean, attribute: "btn-margin" }) btnMarginTop: boolean = false;
  @property({ type: Boolean, attribute: "lazy-load" }) lazyLoad: boolean = false;

  @state() ytEmbedLoaded = signal(false);
  @state() imageLoaded = signal(false);
  @state() overlineTextLoaded = signal(false);

  static styles = [
    unsafeCSS(baseStyles),
    // globalStyles,
    css`
      :host {
        display: flex;
        overflow: hidden;
        width: 100%;
      }

      .bui-fw-banner-container {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: black;
        color: white;
      }

      .img-right {
        flex-direction: row-reverse;
      }

      .img-left {
        flex-direction: row;
      }

      .btn-margin-top {
        margin-top: 56px;
      }

      ::slotted(p) {
        line-height: 1.75rem;
      }

      bui-picture,
      bui-ytembed {
        display: flex;
        width: 50%;
        align-self: stretch;
      }

      .bui-fw-banner-content {
        width: 100%;
        padding: 24px 12px;
        max-width: 100%;
        align-content: center;
      }

      .bui-fw-banner-content bui-overline-text {
        margin-bottom: 24px;
        --overline-color: rgba(255, 255, 255, 1);
      }

      .bui-fw-banner-content .bui-fw-banner-overline {
        font-family: var(--bui-font-sans, sans-serif);
        display: block;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2px;
        margin-bottom: 4px;
      }

      .bui-fw-banner-content .bui-fw-banner-title {
        display: block;
        font-family: var(--bui-font-serif, serif);
        font-weight: 500;
        margin: 0;
        font-size: 48px;
        line-height: 56px;
        font-style: normal;
        margin-bottom: 24px;
      }

      .bui-fw-banner-content .bui-fw-banner-paragraph {
        font-family: var(--bui-font-sans, sans-serif);
        margin: 0;
        font-size: 16px;
        line-height: 1.75rem;
      }

      @media screen and (max-width: 430px) {
        ::slotted(bui-button) {
          --width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        .bui-fw-banner-content {
          padding: 24px 12px;
        }
      }

      @media screen and (min-width: 768px) {
        .bui-fw-banner-content {
          padding: 40px 32px;
          width: 100%;
        }
      }

      @media screen and (max-width: 1024px) {
        .bui-fw-banner-container,
        .img-left,
        .img-right {
          flex-direction: column;
        }

        bui-picture,
        bui-ytembed {
          min-height: 320px;
          width: 100%;
        }
      }

      @media screen and (min-width: 1024px) {
        .bui-fw-banner-content {
          width: 50%;
          padding: 40px;
        }

        bui-picture,
        bui-ytembed {
          min-height: 480px;
        }
      }

      @media screen and (max-width: 1199px) {
        .bui-fw-banner-content {
          max-width: 988px;
        }
      }

      @media screen and (min-width: 1681px) {
        .bui-fw-banner-content {
          max-width: 804px;
        }
      }

      @media screen and (min-width: 2400px) {
        .bui-fw-banner-content {
          max-width: 988px;
        }

        bui-picture,
        bui-ytembed {
          min-height: 640px;
        }
      }
    `,
  ];

  static instanceCounter = 0;
  private titleId: string;

  constructor() {
    super();
    this.titleId = `bui-fw-banner-title-${BuiFwBanner.instanceCounter++}`;
  }

  connectedCallback() {
    super.connectedCallback();
    this.id = `bui-fw-banner-${BuiFwBanner.instanceCounter++}`;
    // this.loadComponents();
  }

  // async loadComponents() {
  //   if (this.variant === "video") {
  //     await import("./bui-ytembed.ts");
  //     this.ytEmbedLoaded.value = true;
  //   } else {
  //     await import("./bui-picture.ts");
  //     this.imageLoaded.value = true;
  //   }
  //   await import("./bui-overline-text.ts");
  //   this.overlineTextLoaded.value = true;
  // }

  // setupLazyLoading() {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         this.loadComponents();
  //         observer.unobserve(this);
  //       }
  //     });
  //   });
  //   observer.observe(this);
  // }

  render() {
    const pageWidth = document.documentElement.clientWidth;
    const imgWidth = pageWidth < 1024 ? pageWidth : Math.floor(pageWidth / 2);

    return html`
      <section class="bui-fw-banner-container ${this.layout || "img-left"}" role="banner" aria-labelledby="${this.titleId}">
        ${this.variant === "video" && this.ytEmbedLoaded.value ? html`<bui-ytembed ratio="${this.imgRatio}" videoID="${this.videoID}" img-src="${this.imgSrc}"></bui-ytembed>` : ""} ${this.variant !== "video" && this.imageLoaded ? html`<bui-picture img-src="${this.imgSrc}" img-loading="${this.lazyLoading}" img-priority="${this.imgPriority || "low"}" img-alt="${this.title} Banner Image" img-ratio=${this.imgRatio} img-fit="${this.imgFit}" img-width="${imgWidth}"></bui-picture>` : ""}
        <div class="bui-fw-banner-content">
          ${this.overlineTextLoaded ? html`<bui-overline-text overline="${this.overline}" title="${this.title}" uid="${this.titleId}"></bui-overline-text>` : ""}
          <div class="bui-fw-banner-paragraph"><slot name="content"></slot></div>
          <div class="bui-fw-banner-btn-container ${this.btnMarginTop ? "btn-margin-top" : ""}"><slot name="cta"></slot></div>
        </div>
      </section>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-fw-banner": BuiFwBanner;
  }
}
