import { LitElement, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { SignalWatcher, html, signal } from "@lit-labs/signals";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";
import baseStyles from "../baseStyles.css?raw";

// Icon below can be use without ?raw with <img>, but it's limited; use unsafeSVG instead
import keyboardArrowDownIcon from "@material-symbols/svg-300/rounded/keyboard_arrow_down.svg?raw";

const arrowDownIcon = unsafeSVG(keyboardArrowDownIcon);
// const open = signal<boolean>(false);

@customElement("bui-accordion-item")
class BuiAccordionItem extends SignalWatcher(LitElement) {
  // @property({ type: Object, reflect: true }) open = signal<boolean>(false);
  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Object }) index = signal<number>(0);
  @property({ type: String }) header = `accordion item #${BuiAccordionItem.titleCounter++}`;
  @property({ type: String }) sect = `bui-sect-${BuiAccordionItem.sectCounter++}`;
  @property({ type: String }) id = `bui-accordion-item-${BuiAccordionItem.instanceCounter++}`;
  @property({ type: Boolean, reflect: true }) noPadding = false;
  // @property({ type: String, attribute: "x-classes" }) xClasses = "productFeedFilterSubtitle";

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        display: block;
        width: 100%;
        line-height: 1.75rem;
        --acc-ffamily: var(--bui-font-sans);
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 0 0 auto;
        transition: transform 0.3s ease;
        fill: var(--mgray800);
      }

      button {
        display: flex;
        width: 100%;
        text-align: left;
        color: var(--mgray800);
        gap: 8px;
        background: none;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        cursor: pointer;
        align-items: center;
      }

      button:hover {
        background-color: #f6f6f6;
      }

      /* button::after {
      content: ""; Clearing default content 
      display: inline-block; Gives the pseudo-element a box model 
      width: 12px; Match the width of your SVG 
      height: 8px; Match the height of your SVG 
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljk5OTk5IDMuODY4NjNMOC40MzQzIDAuNDM0MzExTDkuNTY1NjcgMS41NjU2OEw0Ljk5OTk5IDYuMTMxMzdMMC40MzQzMDMgMS41NjU2OEwxLjU2NTY3IDAuNDM0MzExTDQuOTk5OTkgMy44Njg2M1oiIGZpbGw9ImJsYWNrIi8+PC9zdmc+");
      background-size: cover; Ensures the SVG covers the pseudo-element 
      margin-left: auto;
      transition: transform 0.3s ease; Smooth transition for rotation 
    } */

      /* button[aria-expanded="true"]::after {
      transform: rotate(-180deg);
    } */

      button:focus {
        outline: none;
        background-color: #fafafa;
      }

      button[aria-expanded="true"] {
        background-color: #f6f6f6;
      }

      button[aria-expanded="true"] svg {
        transform: rotate(-180deg);
      }

      .bui-accordion-item-button__header {
        font-family: var(--bui-acc-ffamily);
        font-weight: var(--bui-acc-fweigth);
        font-size: var(--bui-acc-fsize);
      }

      .collapse {
        overflow: hidden;
        transition: height 0.3s ease, padding 0.3s ease;
      }

      .xy-padding {
        padding: 12px 8px 12px 16px;
      }

      .y-padding {
        padding: 16px 0;
      }
    `,
  ];

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    if (name === "open") {
      this.open = newValue !== null;
      this.requestUpdate();
    }
    super.attributeChangedCallback(name, oldValue, newValue);
  }

  firstUpdated() {
    this.updateHeight();
  }

  updated(changedProperties: any) {
    if (changedProperties.has("open")) {
      this.updateHeight();
    }
  }

  static instanceCounter = 0;
  static titleCounter = 1;
  static sectCounter = 0;

  connectedCallback() {
    super.connectedCallback();
    this.id;
    this.sect;
  }

  updateHeight() {
    const content: HTMLElement | null | undefined = this.shadowRoot?.querySelector(".collapse");
    if (!content) return;

    // Use requestAnimationFrame for smoother updates
    requestAnimationFrame(() => {
      if (this.open) {
        content.style.height = `${content.scrollHeight}px`;
      } else {
        content.style.height = "0";
      }
    });
  }

  toggle() {
    // const accItem = this.shadowRoot.querySelector(".bui-accordion-item");
    // accItem.classList.toggle("open");

    this.open = !this.open;
    this.dispatchEvent(
      new CustomEvent("toggle-item", {
        detail: { index: this.index },
        bubbles: true,
        composed: true,
      })
    );
  }

  render() {
    const padding = this.noPadding ? "y-padding" : "xy-padding";

    return html`
      <div class="bui-accordion-item" id="${this.id}">
        <button part="button" type="button" ?collapse=${!this.open} @click=${this.toggle} class="productFeedFilterSubtitle bui-accordion-item-button ${padding}" aria-expanded="${this.open}" aria-controls="${this.sect}">
          <span class="bui-accordion-item-button__header">${this.header}</span>
          ${arrowDownIcon}
        </button>
        <div class="collapse" id="${this.sect}">
          <div id=${this.sect} class="${padding}" role="region" aria-labelledby="${this.id}">
            <slot>
              <strong>This is the ${this.header}'s body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any
              of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
            </slot>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-accordion-item": BuiAccordionItem;
  }
}
