import { LitElement, html, css } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";
import "./bui-picture.ts";

@customElement("bui-watch-card")
export class BuiWatchCard extends LitElement {
  @property({ type: String }) url = "";
  @property({ type: String }) brand = "Rolex";
  @property({ type: String }) model = "Submariner";
  @property({ type: String, attribute: "ref-number" }) refNum = "";
  @property({ type: String }) price = "";
  @property({ type: String }) imgCDN = "https://images.bobswatches.com";
  @property({ type: String, attribute: "img-subdomain" }) imgSubdomain = "";
  @property({ type: String }) imgPlaceholder = "/alpinejs/img-placeholder.jpg";
  @property({ type: Number, attribute: "img-effort" }) effort = 2;
  @property({ type: Number, attribute: "img-quality" }) quality = 50;
  @property({ type: String, attribute: "img-fit" }) imgFit = "contain";
  @property({ type: String, attribute: "img-sharpness" }) sh = "true";
  @property({ type: String, attribute: "img-width" }) imgWidth = "";
  @property({ type: String, attribute: "img-height" }) imgHeight = "212";
  @property({ type: String, attribute: "img-loading" }) imgLoading: "eager" | "lazy" = "lazy";
  @property({ type: String, attribute: "img-priority" }) imgPriority = "low";
  @property({ type: String, attribute: "img-src" }) imgSrc = "/images/Used-Rolex-Submariner-126610-Black-Chromalight-Dial-SKU167399.jpg";
  @property({ type: Boolean, attribute: "img-preload" }) imgPreload = false;

  connectedCallback(): void {
    super.connectedCallback();
  }

  static styles = [
    unsafeCSS(baseStyles),
    css`
      :host {
        /* animation: fadeIn 0.3s ease-in-out; */
        padding: 12px 0;
        display: block;
        min-width: 176px;
      }
      .bui-watch-card {
        text-decoration: none;
        color: black;
        text-align: center;
      }
      .bui-watch-card figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
      }
      .bui-watch-card::after {
        content: "";
        display: block;
        opacity: 0;
        transform: scaleX(0);
        transition: transform 0.314s cubic-bezier(1, 0.25, 0, 0.75), opacity 0.2s ease-in-out;
        position: relative;
        width: 50%;
        height: 1px;
        left: 0;
        margin: 0 auto;
        background-color: var(--color-black, black);
      }
      .bui-watch-card:hover::after {
        opacity: 1;
        transform: scaleX(1);
      }
      a.bui-watch-card:hover {
        color: black;
      }
      .bui-watch-card bui-fluid-pic {
        margin: auto;
      }
      .bui-watch-card-caption {
        margin-block-start: 1rem;
        margin-block-end: 1rem;
      }
      .bui-watch-card-h3 {
        display: flex;
        flex-direction: column;
        text-align: center;
        color: black;
        font-weight: 400;
        gap: 4px;
        margin: 0;
      }
      .bui-watch-card-brand {
        font-family: var(--bui-font-sans);
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 0;
        letter-spacing: 0;
        color: var(--bui-test-color);
      }
      .bui-watch-card-model {
        font-family: var(--bui-font-serif);
        font-weight: 400;
        font-variation-settings: "wght" 400;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0;
        letter-spacing: 0;
      }
      .bui-watch-card-ref {
        font-family: var(--bui-font-sans);
        font-weight: 400;
        font-variation-settings: "wght" 400;
        font-size: 16px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.7);
      }
      .bui-watch-card-price {
        font-family: var(--bui-font-sans);
        font-variation-settings: "wght" 600;
        font-weight: 600;
        line-height: 1;
        margin-top: 1rem;
      }
      @media screen and (min-width: 1024px) {
        .bui-watch-card-model {
          font-size: 1.5rem;
          line-height: 1.2;
        }
      }
    `,
  ];

  protected render() {
    return html`
      <a href="${this.url}" class="bui-watch-card">
        <figure>
          <bui-picture img-src="${this.imgSrc}" img-loading="${this.imgLoading}" img-priority="${this.imgPriority}" img-fit="${this.imgFit}" img-width="${this.imgWidth}" img-height="${this.imgHeight}" ?img-preload="${this.imgPreload}" subdomain="${this.imgSubdomain}"></bui-picture>
          <figcaption class="bui-watch-card-caption">
            <h3 class="bui-watch-card-h3">
              <span class="bui-watch-card-brand">${this.brand}</span>
              <span class="bui-watch-card-model">${this.model}</span>
              ${this.refNum ? html`<span class="bui-watch-card-ref">${this.refNum}</span>` : null}
            </h3>
            ${this.price ? html`<div class="bui-watch-card-price">$${this.price}</div>` : null}
          </figcaption>
        </figure>
      </a>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-watch-card": BuiWatchCard;
  }
}
