import { LitElement, html } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../../baseStyles.css?raw";
import hostStyles from "./Biu2colLayout.css?raw";
import "../bui-button.js";
import "../bui-overline-text.js";

@customElement("bui-2col-layout")
export class Bw2ColLayout extends LitElement {
  @property({ type: String }) title: string = "Hello world!";
  @property({ type: Boolean }) noTitle: boolean = false;
  @property({ type: String }) overline: string = "";
  @property({ type: Boolean }) right: boolean = false;
  @property({ type: Boolean }) btn: boolean = false;
  @property({ type: String }) btnLink: string = "#";
  @property({ type: String }) btnText: string = "Call Us Now";
  @property({ type: Boolean }) noOverline: boolean = false;
  @property({ type: Boolean }) noPadding: boolean = false;
  @property({ type: Boolean }) xPadding: boolean = false;
  @property({ type: Boolean }) contained: boolean = false;

  static instanceCounter = 0;

  static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];

  connectedCallback(): void {
    super.connectedCallback();
    this.id = `bui-2col-layout-${Bw2ColLayout.instanceCounter++}`;
  }

  render() {
    return html`
      <div class="${this.contained ? "bui-2col-layout--contained" : "bui-2col-layout"}">
        <div class="bui-2col-layout-content${this.right ? "--right" : ""}">
          <div class="bui-2col-layout__content ${this.xPadding ? "x-padding" : ""}">
            ${this.noTitle ? null : html`<bui-overline-text overline="${this.overline}" title="${this.title}"></bui-overline-text>`}
            <slot name="content"></slot>
            ${this.btn ? html`<bui-button kind="link" variant="" link="${this.btnLink}" size="xl"> ${this.btnText} </bui-button>` : null}
          </div>
          <div class="bui-2col-layout__media">
            <slot name="media"></slot>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-2col-layout": Bw2ColLayout;
  }
}
