import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
// import { unsafeCSS } from "lit";
// import baseStyles from "../../baseStyles.css?raw";
// import hostStyles from "./BuiToc.css?raw";
import { CSSResultGroup } from "lit";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiToc.css";

@customElement("bui-toc")
export class BuiToc extends LitElement {
  @property({ type: String }) title = "Table of Contents";
  @property({ type: Array }) headers: Array<any> = [];
  @property({ type: String }) query = ".main-content h2";

  // static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];
  static styles = [baseStyles, hostStyles] as CSSResultGroup;
  static instanceCounter = 0;

  connectedCallback() {
    super.connectedCallback();
    this.updateHeaders();
  }

  updateHeaders() {
    const headers = document.querySelectorAll(this.query);
    this.headers = Array.from(headers).map((header, index) => {
      const id = `toc-title-${index}`;
      header.id = id;
      return { id, text: header.textContent };
    });
    this.requestUpdate(); // This is crucial to update the view with the new headers
  }

  render() {
    return html`
      <div class="content-toc-container">
        <div class="content-toc-wrapper">
          <nav class="content-toc">
            <span class="content-toc-title">${this.title}</span>
            ${this.headers.map((header) => html` <a href="#${header.id}" class="content-toc__jumplink">${header.text}</a> `)}
          </nav>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-toc": BuiToc;
  }
}
