import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import baseStyles from "../baseStyles.css?raw";

const chevronRightIconGreen: any =
  "url('data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCcgZmlsbD0nI0IxRjM0NycgY2xhc3M9J2JpIGJpLWNoZXZyb24tcmlnaHQnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNNC42NDYgMS42NDZhLjUuNSAwIDAgMSAuNzA4IDBsNiA2YS41LjUgMCAwIDEgMCAuNzA4bC02IDZhLjUuNSAwIDAgMS0uNzA4LS43MDhMMTAuMjkzIDggNC42NDYgMi4zNTRhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+')";
const chevronRightIconBlack: any =
  "url('data:image/svg+xml;charset=UTF-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCcgZmlsbD0nIzAwMDAwJyBjbGFzcz0nYmkgYmktY2hldnJvbi1yaWdodCcgdmlld0JveD0nMCAwIDE2IDE2Jz48cGF0aCBmaWxsLXJ1bGU9J2V2ZW5vZGQnIGQ9J000LjY0NiAxLjY0NmEuNS41IDAgMCAxIC43MDggMGw2IDZhLjUuNSAwIDAgMSAwIC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDgtLjcwOEwxMC4yOTMgOCA0LjY0NiAyLjM1NGEuNS41IDAgMCAxIDAtLjcwOHonLz48L3N2Zz4=')";

export const buttonStyles = css`
  :host {
    display: flex;
    width: var(--width, 100%);
    min-width: var(--min-width, 100%);
    max-width: var(--max-width, 100%);
  }

  :host([full-width]) {
    width: var(--width, 100%);
    min-width: var(--min-width, 100%);
    max-width: var(--max-width, 100%);
  }

  :host([full-width])::part(button),
  :host([full-width])::part(link) {
    width: var(--width, 100%);
    min-width: var(--min-width, 100%);
    max-width: var(--max-width, 100%);
  }

  .bui-btn,
  .bui-btn-outline,
  .bui-btn-transparent,
  .bui-btn-transparent-blk,
  .bui-btn-white,
  .bui-btn-50blk,
  .bui-btn-brand {
    width: var(--width, 100%);
    min-width: var(--min-width, 100%);
    max-width: var(--max-width, 100%);
    display: flex;
    font-family: var(--bui-font-sans);
    font-weight: 600;
    flex-direction: row;
    align-items: center;
    background: black;
    color: white;
    border-radius: 64px;
    font-size: 14px;
    line-height: 1;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
  }

  .bui-btn:hover,
  .bui-btn-outline:hover,
  .bui-btn-transparent:hover,
  .bui-btn-white:hover,
  .bui-btn-50blk:hover,
  .bui-btn-brand:hover {
    color: var(--brand400);
    cursor: pointer;
  }

  .bui-btn:hover::after,
  .bui-btn-outline:hover::after,
  .bui-btn-transparent:hover::after,
  .bui-btn-transparent-blk:hover::after,
  .bui-btn-white:hover::after,
  .bui-btn-50blk:hover::after,
  .bui-btn-brand:hover::after {
    content: ${unsafeCSS(chevronRightIconGreen)};
    margin-left: 8px;
    opacity: 1;
  }

  .bui-btn::before,
  .bui-btn-outline::before,
  .bui-btn-transparent::before,
  .bui-btn-transparent-blk::before,
  .bui-btn-white::before,
  .bui-btn-50blk::before,
  .bui-btn-brand::before {
    content: "";
  }

  .bui-btn::after,
  .bui-btn-outline::after,
  .bui-btn-transparent::after,
  .bui-btn-transparent-blk::after,
  .bui-btn-white::after,
  .bui-btn-50blk::after,
  .bui-btn-brand::after {
    content: "";
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  .bui-btn-outline,
  .bui-btn-transparent,
  .bui-btn-transparent-blk,
  .bui-btn-white {
    background-color: white;
    color: black;
    border: 1px solid black;
  }

  .bui-btn-outline:hover,
  .bui-btn-transparent:hover,
  .bui-btn-white:hover {
    color: black;
    background-color: var(--brand400);
  }

  .bui-btn-transparent-blk:hover {
    color: var(--brand400);
    background-color: black;
    border-color: black;
  }

  .bui-btn-outline:hover::after,
  .bui-btn-transparent:hover::after,
  .bui-btn-white:hover::after {
    content: ${unsafeCSS(chevronRightIconBlack)};
  }

  .bui-btn-transparent,
  .bui-btn-transparent-blk {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }

  .bui-btn-transparent,
  .bui-btn-transparent-blk {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }

  .bui-btn-white {
    border: none;
  }

  .bui-btn-white:hover::after {
    content: ${unsafeCSS(chevronRightIconBlack)};
  }

  .bui-btn-brand {
    color: black;
    background-color: var(--brand400);
    border: none;
  }

  .bui-btn-brand:hover {
    color: black;
  }

  .bui-btn-brand:hover::after {
    content: ${unsafeCSS(chevronRightIconBlack)};
  }

  .bui-btn-50blk {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: 0;
  }

  .bui-btn-50blk:hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--brand400);
  }

  .bui-btn-50blk:hover::after {
    content: ${unsafeCSS(chevronRightIconGreen)};
  }

  .bui-btn-sm {
    min-height: 32px;
    padding: 0 16px;
  }

  .bui-btn-md {
    min-height: 40px;
    padding: 0 20px;
  }

  .bui-btn-lg {
    min-height: 48px;
    padding: 0 24px;
  }

  .bui-btn-xl {
    min-height: 56px;
    padding: 0 28px;
  }

  .bui-btn-2xl {
    min-height: 64px;
    padding: 0 32px;
  }

  /* Medium screens */
  @media (min-width: 640px) {
    :host {
      display: flex;
      width: var(--width, fit-content);
      min-width: var(--min-width, 96px);
      max-width: var(--max-width, 100%);
    }

    :host([full-width]) {
      width: var(--width, 100%);
      min-width: var(--min-width, 96px);
      max-width: var(--max-width, 100%);
    }

    :host([full-width])::part(button),
    :host([full-width])::part(link) {
      width: var(--width, 100%);
      min-width: var(--min-width, 96px);
      max-width: var(--max-width, 100%);
    }

    .bui-btn,
    .bui-btn-outline,
    .bui-btn-transparent,
    .bui-btn-transparent-blk,
    .bui-btn-white,
    .bui-btn-50blk,
    .bui-btn-brand {
      width: var(--width, auto);
      min-width: var(--min-width, 96px);
      max-width: var(--max-width, 100%);
    }
  }

  /* Large screens */
  @media (min-width: 1200px) {
  }
`;

@customElement("bui-button")
export class BuiButton extends LitElement {
  static formAssociated = true; // Enable form association

  @property({ type: String }) id = "";
  @property({ type: String }) label = "Default Label";
  @property({ type: String }) link = "#";
  @property({ type: String }) kind = "button"; // 'button', 'submit', or 'link'
  @property({ type: String }) variant = "default";
  @property({ type: String }) styles = "";
  @property({ type: String }) config = "";
  @property({ type: String }) size = "default";
  @property({ type: Boolean, attribute: "full-width" }) fullWidth = false;

  static instanceCounter = 0;

  connectedCallback() {
    super.connectedCallback();
    if (!this.id) {
      this.id = `bui-button-${BuiButton.instanceCounter++}`;
    }
  }

  computeVariant(variant: string) {
    switch (variant) {
      case "outline":
        return "bui-btn-outline";
      case "transparent":
        return "bui-btn-transparent";
      case "transparent-blk":
        return "bui-btn-transparent-blk";
      case "white":
        return "bui-btn-white";
      case "brand":
        return "bui-btn-brand";
      case "50blk":
        return "bui-btn-50blk";
      default:
        return "bui-btn";
    }
  }

  computeSize(size: string) {
    switch (size) {
      case "sm":
        return "bui-btn-sm";
      case "md":
        return "bui-btn-md";
      case "lg":
        return "bui-btn-lg";
      case "xl":
        return "bui-btn-xl";
      case "2xl":
        return "bui-btn-2xl";
      default:
        return "bui-btn-md";
    }
  }

  static styles = [unsafeCSS(baseStyles), buttonStyles];

  render() {
    if (this.kind === "link") {
      return html`<a href=${this.link} class="${this.computeVariant(this.variant)} ${this.computeSize(this.size)}" id="${this.id}" style="${this.config} ${this.styles}" part="link"><slot name="leading"></slot>${this.label}<slot name="trailing"></slot></a>`;
    } else {
      return html`<button class="${this.computeVariant(this.variant)} ${this.computeSize(this.size)}" id="${this.id}" style="${this.config} ${this.styles}" type="button" part="button"><slot name="leading"></slot>${this.label}<slot name="trailing"></slot></button>`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-button": BuiButton;
  }
}
